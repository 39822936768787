import React from 'react';
import Sidebar from "../../../components/Sidebar/Sidebar";
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment'

const TransactionDetails = ({transactions}) => {

  const {id} = useParams();

  const transaction = transactions.find((val) => val.id === id);

      const getColor = (status) => {
        switch (status) {
          case "Pending":
            return "#0898D7";
          case "Successful":
            return "#00B327";
          case "Processing":
            return "#FEC400";
          case "Failed":
            return "#ff0000";
          default:
            break;
        }
      };

    return ( 
        <>
        <Sidebar />
        <div className="main">
            <div className="contain">

               {/* back button */}
          <div className="container mt-3 mb-5">
            <Link
              to="/transactions"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                alignItems: "center",
                fontSize: "18px",
              }}
            >
              <span>
                <i className="mdi mdi-arrow-left"></i> Go Back
              </span>
            </Link>

            <hr />

            {/* Card layout */}
            <div className="mt-4 mb-4 card-head">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    background: "#FFDDD2",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ color: "#CB644E", fontWeight: "bold" }}>
                  {transaction.sellerDetails.firstName.charAt(0)}
                    {transaction.sellerDetails.lastName.charAt(0)}
                  </span>
                </div>
                <div className="ml-3 mt-1">
                  <h5>
                  {transaction.sellerDetails.firstName} {transaction.sellerDetails.lastName}
                  </h5>
                </div>
              </div>


            </div>

            {/* ---- */}
            <hr />

            {/* Card info layout */}
            <div className="row mt-4 mb-4">
              <div className="col-lg-12">
                {/* general information */}
                <div>
                  <h6 style={{ fontWeight: "bold" }}>Transaction Details</h6>
                </div>
                {/* Card details */}
                <div className="row mt-4">
                  <div className="col-lg-3">
                    <p className="mb-0">Transaction Id</p>
                    <p className="mt-1" style={{ color: "#898D93" }}>
                  {transaction ? transaction.id : "3445"}
                    </p>
                  </div>
                  <div className="col-lg-3">
                      <p className="mb-0">Date</p>
                    <p className="mt-1 mb-0" style={{ color: "#898D93" }}>
                       {
                         transaction ?
                         <Moment format="MMMM Do, YYYY">
                         {transaction.createdAt}
                        </Moment> 
                        : 
                        ""
                       }
                    </p>
                  </div>

                  <div className="col-lg-3">
                  <p className="mb-0">Type</p>
                    <p className="mt-1" style={{ color: "#898D93" }}>
                   {transaction ? transaction.type : ''}
                    </p>
                  </div>

                  <div className="col-lg-3">
                  <p className="mb-0">Description</p>
                    <p className="mt-1 mb-0" style={{ color: "#898D93" }}>
                      {transaction ? transaction.transactionLabel : ''}
                    </p>
                  </div>

                  <div className="col-lg-3 mt-3">
                  <p className="mb-0">Amount</p>
                    <p className="mt-1 mb-0" style={{ color: "#898D93" }}>
                        NGN {transaction ? transaction.amount : '0'}
                    </p>
                  </div>

                  {transaction && transaction.transactionLabel === "Withdrawal Request" ? (
                <>
                   <div className="col-lg-3 mt-3">
                  <p className="mb-0">Account Number</p>
                    <p className="mt-1 mb-0" style={{ color: "#898D93" }}>
                       {transaction ? transaction.accountNumber : '0'}
                    </p>
                  </div>

                  <div className="col-lg-3 mt-3">
                  <p className="mb-0">Bank Name</p>
                    <p className="mt-1 mb-0" style={{ color: "#898D93" }}>
                       {transaction ? transaction.bankName : '0'}
                    </p>
                  </div>

                  <div className="col-lg-3 mt-3">
                  <p className="mb-0">Account Name</p>
                    <p className="mt-1 mb-0" style={{ color: "#898D93" }}>
                       {transaction ? transaction.accountName : '0'}
                    </p>
                  </div>                  
                </>
              ) : (
                ""
              )}

                {transaction && transaction.isBillPayment ? (
                        <>
                           <div className="col-lg-3 mt-3">
                          <p className="mb-0">Receiver's Value</p>
                            <p className="mt-1 mb-0" style={{ color: "#898D93" }}>
                            {transaction
                                          ? transaction.customer
                                          : ""}
                            </p>
                          </div> 
                                 
                        </>
                      ) : (
                        ""
                  )}

                  {transaction.isBillPayment && transaction.hasOwnProperty('token') ? (
                      <>
                        <div className="col-lg-3 mt-3">
                        <p className="mb-0">Token</p>
                            <p className="mt-1 mb-0" style={{ color: "#898D93" }}>
                            {transaction
                                          ? transaction.token
                                          : ""}
                            </p>
                        </div>                  
                      </>
                    ) : (
                      ""
                    )}

    

                  <div className="col-lg-3 mt-lg-3 mt-3">
                  <p className="mb-0">Status</p>
                    <p
                      className="mt-1 mb-0"
                      style={{
                        color: getColor(transaction.status),
                      }}
                    >
                    {transaction ? transaction.status : ''}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


            </div>
        </div>
        </>
     );
}

const mapStateToProps = (state) => {
  return {
    transactions: state.transaction.Transactions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetails);