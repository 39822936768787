

const initState = {
    requests: [],
    loader: false,
    approveloader: false,
    declineloader: false
  };
  
  const requestReducer = (state = initState, action) => {
    switch (action.type) {
    case 'Requests':
        return{
            ...state,
            requests: action.data
        }
    case "LoadRequest":
        return{
            ...state,
            loader: true
        }
    case "StopLoadRequest":
        return{
            ...state,
            loader: false
        }
    case 'RequestLoader':
        return{
            ...state,
            approveloader: true
        }
    case 'StopRequestLoader':
        return{
            ...state,
            approveloader: false
        }
    case 'cancelRequestLoader':
        return{
        ...state,
        declineloader: true
        }
 case 'StopCancelRequestLoader':
    return{
        ...state,
        declineloader: false
    }
      default:
        return state;
    }
  };
  
  export default requestReducer;
  