

const initState = {
    isAuthenticated: false,
    token: '',  
    firstname: "",
    lastname: "",
    email: "",
    id: "",
    phoneNumber: "",
    isVerified: false,
    isEnabled: false,
    role: "",
    profilePic: "",
    accountDetails: [],
    loading: false
  };
  
  const authReducer = (state = initState, action) => {
    switch (action.type) {
      case 'LOGIN_SUCCESS':
        localStorage.setItem("token", action.data.token)
          return {
                ...state,
                loading: false,
                isAuthenticated: true,
                token: action.data.token,
                firstname: action.data.profile.firstName,
                lastname: action.data.profile.lastName,
                email: action.data.profile.email,
                id: action.data.profile.id,
                phoneNumber: action.data.profile.phoneNumber,
                isVerified: action.data.profile.isVerified,
                isEnabled: action.data.profile.isEnabled,
                role: action.data.profile.role,
                profilePic: action.data.profile.profilePic,
                accountDetails: action.data.profile.accountDetails
        }
        case 'PasswordChanged':
          return{
            ...state,
            loading: true,
            isAuthenticated: false,
            token: '',
            firstname: '',
            lastname: '',
            email: '',
            id: '',
            phoneNumber: '',
            isVerified: '',
            isEnabled: '',
            role: '',
            profilePic: '',
            accountDetails: []
          }
        case 'clearPasswordStatus':
          return{
            ...state,
            loading: false
          }
          case 'PhotoLoader':
            return{
                ...state,
                photoloader: true
            }
        case 'StopPhotoLoader':
            return{
                ...state,
                photoloader: false
          }
        case 'profilePicture':
            return{
                ...state,
                profilePic: action.image   
          }
        case 'PROFILE_UPDATE':
          return{
            ...state
          }
      default:
        return state;
    }
  };
  
  export default authReducer;
  