import React, { useEffect } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import DataTable from 'react-data-table-component'
import {connect} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment'
import { getRequests } from '../../../store/actions/requests';


const AdminWithdrawals = ({fetchRequest, requests, loader}) => {

    useEffect(()=>{
      fetchRequest()
    },[fetchRequest])

  const navigate = useNavigate();
        
      const columns = [
        {
          name: "First Name",
          cell: row => <span
          > 
        { row['userDetails']['firstName']  }
        </span>,
          sortable: true
        },
        {
          name: "Last Name",
          cell: row => <span
          > 
        { row['userDetails']['lastName']  }
        </span>,
          sortable: true
        },
        {
          name: "Account Number",
          cell: row => <span
          > 
        { row['accountNumber']  }
        </span>,
          sortable: true
        },
        {
          name: "Amount",
          cell: row => <span> 
              {`NGN ${row.amount}`}
          </span>
        },
        {
            name: "Date Requested",
            cell: row => <span>
              <Moment format="MMMM Do, YYYY">
              {row.createdAt}
              </Moment>
          </span>
        },
        {
            name: "Status",
            cell: row => <span
            className={getStatusColor(row.status)}
            > 
            {`${row.status}`}
            </span>
          },
          {
            name: 'Actions',
            button: true,
            cell: row => <button
            className="btn btn-sm btn-view"
            onClick={() => {
                ViewTransact(row.id)}}
            >View</button>,
          }
      ];

    const ViewTransact = (id) =>{
       navigate("/withdrawal/"+id)
     }

    const getStatusColor = (val) =>{
      let result;
      switch(val){
        case 'Pending':
          result = 'defaultDiv'
          break;
        case 'Completed':
          result = 'success-color'
          break;
        case 'Processing':
          result = 'processing-color'
          break;
       case 'Declined':
         result = 'declined-color'
         break;
        default:
         break;
      }
      return result;
    }

    return ( 
        <>
        <Sidebar />
        <div className="main">
            <div className="contain">

                {/* withdrawals */}
                <div className="mt-5 mb-5">
                        <DataTable
                        title="Withdrawal Requests"
                        columns={columns}
                        data={requests}
                        pagination
                        persistTableHead
                        progressPending={loader}
                        />
                </div>


            </div>
        </div>
        </>
     );
}

const mapStateToProps = (state) =>{
    return{
      requests: state.request.requests,
      loader: state.request.loader
    }
  }
  
  const mapDispatchToProps = (dispatch) =>{
    return{
      fetchRequest: () => dispatch(getRequests()),
    }
  }
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminWithdrawals);