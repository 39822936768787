import React, { useEffect } from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import DataTable from 'react-data-table-component'
import {connect} from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { getTransactions } from '../../../store/actions/transactions';
import Moment from 'react-moment'


const AdminTransactions = ({loader, fetchTransactions, transactions}) => {
  
  
  useEffect(()=>{
    fetchTransactions()
  },[fetchTransactions])

  const navigate = useNavigate()

  const getStatusColor = (val) =>{
    let result;
    switch(val){
      case 'Pending':
        result = 'defaultDiv'
        break;
      case 'Processing':
        result =  'processing-color';
        break;
      case 'Successful':
        result = 'success-color'
        break;
     case 'Failed':
       result = 'declined-color'
       break;
      default:
       break;
    }
    return result;
  }

    const columns = [
        {
            name: "Transaction Type",
            selector: (row) => row.type,
          },
          {
            name: "Amount",
            selector: (row) => `NGN ${row.amount}`,
          },
        {
          name: "Date Initiated",
          cell: row => <span>
          <Moment format="MMMM Do, YYYY">
             {row.createdAt}
            </Moment>   
      </span>
      },
        {
            name: "Status",
            cell: row => <span
            className={getStatusColor(row.status)}
            > 
           {`${row.status}`}
           </span>,
          },
          {
            name: 'Actions',
            button: true,
            cell: row => <button
            className="btn btn-sm btn-view"
            onClick={() => {
                ViewTransact(row.id)}}
             >View</button>,
          }
      ];

    const ViewTransact = (id) =>{
      navigate("/transaction/"+id)
     }

    return (
        <>
        <Sidebar />
        <div className="main">
            <div className="contain">

                {/* withdrawals */}
                <div className="mt-5 mb-5">
                        <DataTable
                        title="Transactions"
                        columns={columns}
                        data={transactions}
                        pagination
                        persistTableHead
                        progressPending={loader}
                        />
                </div>


            </div>
        </div>
        </>
      );
}

const mapStateToProps = (state) =>{
    return{
      loader: state.transaction.loader,
      transactions: state.transaction.Transactions
    }
  }
  
  const mapDispatchToProps = (dispatch) =>{
    return{
      fetchTransactions: () => dispatch(getTransactions()),
    }
  }
 
export default connect(mapStateToProps, mapDispatchToProps)(AdminTransactions);