import {GetApi} from '../helpers'



const getToken = () => {
	const token = localStorage.getItem("token");
	return token
}


   // Get ouflow chart data for month,year, and week functionality
   export const ChartRequest = (value) => {
    return async (dispatch, getState) => {
      try {
          const time = value
        const res = await GetApi("graph/admin?time="+time, getToken());
        if (res.status === 200) {
            
          dispatch({ type: "ChartData", data: res.data});
        }
        if(res.status === 400){
          dispatch({ type: "Chart_Error", err: res.data });
        }
      } catch (err) {
        console.log(err)
      }
    };
  };
  