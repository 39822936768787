import React,{useEffect} from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { Form, Formik } from "formik";
import { settingsValidator } from "../../../validationSchema/validator";
import './Settings.css'
import { connect } from 'react-redux';
import { getWithdrawSetings, updateWithdrawSettings } from '../../../store/actions/admin';

const WithdrawSettingsPage = ({configFetch, updateSettings, config, maximumAmount, minimumAmount, userRole}) => {

    const handleSubmit = async (values, setSubmitting) => {
        console.log(values)
        await updateSettings(values);
    }

    // make call to fetch all settings
  useEffect(() => {
    configFetch();
  }, [configFetch]);

    return ( 
        <>
         <Sidebar />
        <div className="main">
            <div className="contain">

            <div className="admin-card mt-5 mb-5">
            <div className="admin-div">
              <div>
                <h5
                  className="text-center"
                  style={{ color: "#0898D7", fontWeight: "bold" }}
                >
                    Withrawal Settings
                </h5>
              </div>

              {/* form submission */}
              <Formik
                onSubmit={(values, { setSubmitting }) =>
                  handleSubmit(values, setSubmitting)
                }
                validationSchema={settingsValidator}
                enableReinitialize={true}
                initialValues={{
                    minAmount:
                        config && config.hasOwnProperty("minimumWithdrawer")
                          ? minimumAmount
                        : 0,
                    maxAmount:
                    config && config.hasOwnProperty("maximumWithdrawer")
                        ? maximumAmount
                        : 0
                }}
              >
                {({
                  handleChange,
                  isSubmitting,
                  handleSubmit,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>

                    {/* Minimum withdrawal amount */}
                    <div className="form-group input-container mt-4">
                        <label htmlFor="maxAmount">
                            Maximum Withdrawal Amount (NGN)
                          </label>
                     
                      <input
                        className="form-control settings-input"
                        type="text"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="maxAmount"
                        style={{width: '100%'}}
                        value={values.maxAmount}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.maxAmount && errors.maxAmount}
                      </small>
                    </div>

                    {/*    Minimum Withdrawal Amount (NGN) */}
                    <div className="form-group input-container mt-4">
                        <label htmlFor="minAmount">
                             Minimum Withdrawal Amount (NGN)
                          </label>

                      <input
                        className="form-control settings-input"
                        type="text"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="minAmount"
                        style={{width: '100%'}}
                        value={values.minAmount}
                      />
                      <small style={{ color: "#dc3545" }}>
                        {touched.minAmount && errors.minAmount}
                      </small>
                    </div>

                    <button
                      type="submit"
                      disabled={isSubmitting || userRole === 'SubAdmin'}
                      className="btn btn-blueTacit btn-block mt-4"
                    >
                     Update Settings
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>


                
            </div>
        </div>
        </>
     );
}



const mapStateToProps = (state) => {
    return {
      minimumAmount: state.admin.config.minimumWithdrawer,
      maximumAmount: state.admin.config.maximumWithdrawer,
      config: state.admin.config,
      userRole: state.auth.role
    };
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      configFetch: () => dispatch(getWithdrawSetings()),
      updateSettings: (creds) => dispatch(updateWithdrawSettings(creds)),
    };
  }
 
export default connect(mapStateToProps, mapDispatchToProps)(WithdrawSettingsPage);