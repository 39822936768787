import * as Yup from "yup";


export const loginValidator = Yup.object({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password cannot be less than 6 characters")
      .required("Password is required"),
  });

  
export const forgotPasswordValidator = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const addGiftCardValidator = Yup.object({
  category: Yup.string()
    .required("Category is required"),
  newcategory: Yup.string(),
  subcategory: Yup.string()
    .required("Subcategory is required"),
  minAmount: Yup.string()
  .required("Minimum Amount is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount"),
  maxAmount: Yup.string()
  .required("Maximum Amount is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount"),
  rate: Yup.string()
  .required("Rate is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount"),
  terms: Yup.string(),
});

export const RegisterAdminValidator = Yup.object({
  firstname: Yup.string().required("Firstname is required"),
  lastname: Yup.string().required("Lastname is required"),
  phoneNumber: Yup.string()
  .min(11, 'Phone number cannot be less than 11 digits')
  .max(11, 'Exceeded characters for phone number')
  .required("Phonenumber is required").matches(
    /^-?[0-9]+(.[0-9]{1-7})?$/,
    "Enter a valid phone number"),
  email: Yup.string().email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Password cannot be less than 3 characters")
    .required("Password is required"),
});


export const ChangePasswordValidator = Yup.object({
  password:  Yup.string()
  .required('Password is required'),
  newpassword:  Yup.string()
  .required('Enter a new password'),
   confirm_password:Yup.string()
   .required("Passwords must match")
   .oneOf([Yup.ref('newpassword'), null], 'Passwords must match')
});


export const DeclineTradeValidator = Yup.object({
  reason: Yup.string().required("Reason is required"),
});


export const settingsValidator = Yup.object({
  minAmount: Yup.string()
    .required("Minimum Amount is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount"),
  maxAmount: Yup.string()
    .required("Maximum Amount is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid amount")
});
  