import React,{useState, useRef} from 'react'
import Sidebar from "../../../components/Sidebar/Sidebar";
import { Link, useParams, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import Modal from "react-bootstrap/Modal";
import accountCircle from "../../../assets/images/accountCircle.svg";
import closeIcon from "../../../assets/images/closeIcon.svg";
  import Moment from 'react-moment'
import { ApproveRequest, DeclineRequest } from '../../../store/actions/requests';

const WithdrawalDetails = (props) => {

    const { ApproveRequest,
      DeclineRequest,
        approveloader,
        requests,
        declineloader,} = props

    const {id} = useParams();

    const navigate = useNavigate();


  const request = requests.find((val) => val.id === id);

  const ref = useRef();

  const [editShow, setEditShow] = useState(false);

  const handleEditClose = () => setEditShow(false);

  const [declineShow, setDeclineShow] = useState(false);

  const handleDeclineClose = () => setDeclineShow(false);

  const handleDeclineShow = () => setDeclineShow(true);

    // get default account details
  const account = request.userDetails.accountDetails.length
  ? request.userDetails.accountDetails.find((pro) => pro.isDefault === true)
  : "";

   // approve and decline layout show based on payment status
   const PayLayout = (status) => {
    switch (status) {
      case "Pending":
        return (
          <div>
            <button
              type="submit"
              className="btn btn-active mt-lg-0 mt-3"
              onClick={() => {
                Approve(id);
              }}
            >
              Approve
            </button>
            <button
              type="submit"
              disabled={declineloader}
              className="btn btn-suspend ml-lg-3 mt-lg-0 mt-3"
              onClick={() => {
                Decline(id);
              }}
            >
              Decline
            </button>
          </div>
        );

      case "Declined":
        return <div></div>;
      case "Failed":
        return   <div>
            <button
              type="submit"
              className="btn btn-active mt-lg-0 mt-3"
              onClick={() => {
                RetryPayment(id);
              }}
            >
              Retry Payment
            </button>
          </div>;
      case "Successful":
        return <div>
        </div>
      default:
        return <div></div>;
    }
  };


  const handleEditShow = () => {
    setEditShow(true);
  };

  const Approve = () => {
    handleEditShow();
  };

  const RetryPayment = () => {
    handleEditShow();
  };

  const Decline = () => {
    handleDeclineShow();
  };

  const Transfer = () => {
  
    ApproveRequest(id);

    setTimeout(() => {
      navigate("/withdrawals");
    }, 3000);
  };

  const handleDecline = async () => {

    await DeclineRequest(id);

    setTimeout(() => {
      navigate("/withdrawals");
    }, 3000);
  };

  const getPayColor = (status) => {
    switch (status) {
      case "Declined":
        return "#ff0000";
      case "Pending":
        return "#0898D7";
      case "Successful":
        return "#00B327";
      case "Completed":
          return "#00B327";
      case "Processing":
        return "#FEC400";
      default:
        break;
    }
  };


    return ( 
        <>

         {/* modal for displaying details */}
      <Modal
        show={editShow}
        ref={ref}
        {...props}
        backdrop="static"
        onHide={handleEditClose}
      >
        <Modal.Header>
          <div
            onClick={handleEditClose}
            style={{
              position: "absolute",
              right: "35px",
              top: "20px",
              cursor: "pointer",
            }}
          >
            <img src={closeIcon} alt="close" width="40" height="40" />
          </div>
        </Modal.Header>

        {/*container */}
        <div
          className="d-none d-md-block"
          style={{ position: "absolute", left: "70px", top: "0px" }}
        >
          <img alt="login" src={accountCircle} width="350" height="140" />
        </div>

        <div
          className="text-center contain-head mt-4 mt-lg-5"
          style={{ position: "relative" }}
        >
          <h3 className="login-text">Confirm Payment</h3>
        </div>

        <div className="container modal-contain">
          {/* confirm details layout */}

          <div className="text-center">
            <h6 style={{ fontWeight: "bold", lineHeight: "22px" }}>
              Kindly confirm that the credentials are correct!.
            </h6>
          </div>

          {/* account name */}
          <div
            className="mt-4"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              <p>Account Name:</p>
            </div>
            <div>
              <p>{account.accountName ? account.accountName : ""}</p>
            </div>
          </div>

          {/* Account Number */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p>Account Number:</p>
            </div>
            <div>
              <p>{account.accountNumber ? account.accountNumber : ""}</p>
            </div>
          </div>

          {/* Bank Name */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p>Bank Name:</p>
            </div>
            <div>
              <p>{account.bankName ? account.bankName : ""}</p>
            </div>
          </div>

          {/* bank code */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p>Bank Code:</p>
            </div>
            <div>
              <p>{account.bankCode ? account.bankCode : ""}</p>
            </div>
          </div>

          {/* amount */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <p>Amount:</p>
            </div>
            <div>
              <p>
                NGN{" "}
                {request.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  ? request.amount
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : ""}
              </p>
            </div>
          </div>

          {/* end of details layout */}

          <div className="text-center mt-4">
            <button
              type="submit"
              disabled={approveloader}
              onClick={Transfer}
              className="btn btn-blueTacit"
            >
              Transfer Now
            </button>
          </div>
        </div>
      </Modal>
      {/* end of  transfer confirmation modal */}

      {/* modal for decline trade */}
      <Modal
        show={declineShow}
        ref={ref}
        {...props}
        backdrop="static"
        onHide={handleDeclineClose}
      >
        <Modal.Header>
          <div
            onClick={handleDeclineClose}
            style={{
              position: "absolute",
              right: "35px",
              top: "20px",
              cursor: "pointer",
            }}
          >
            <img src={closeIcon} alt="close" width="40" height="40" />
          </div>
        </Modal.Header>

        {/*container */}
        <div
          className="d-none d-md-block"
          style={{ position: "absolute", left: "70px", top: "0px" }}
        >
          <img alt="login" src={accountCircle} width="350" height="140" />
        </div>

        <div
          className="text-center contain-head mt-4 mt-lg-5"
          style={{ position: "relative" }}
        >
          <h3 className="login-text">Decline Payment</h3>
        </div>

        <div className="container modal-contain">
          {/* confirm details layout */}

          <div className="text-center">
            <h6 style={{ fontWeight: "bold", lineHeight: "22px" }}>
              Kindly confirm the declination of this payment.
            </h6>
          </div>


          <div className="text-center mt-4">
            <button
              type="submit"
              onClick={handleDecline}
              className="btn btn-blueTacit"
            >
             Submit
            </button>
            </div>

          {/* end of details layout */}
        </div>
      </Modal>
      {/* end of decline trade modal */}

         <Sidebar />
        <div className="main">
            <div className="contain">

             {/* back button */}
          <div className="container mt-3 mb-5">
            <Link
              to="/withdrawals"
              style={{
                textDecoration: "none",
                color: "black",
                display: "flex",
                alignItems: "center",
                fontSize: "18px",
              }}
            >
              <span>
                <i className="mdi mdi-arrow-left"></i> Go Back
              </span>
            </Link>

            <hr />

            {/* Card layout */}
            <div className="mt-4 mb-4 card-head">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    background: "#FFDDD2",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ color: "#CB644E", fontWeight: "bold" }}>
                    {request.userDetails.firstName.charAt(0)}
                    {request.userDetails.lastName.charAt(0)}
                  </span>
                </div>
                <div className="ml-3 mt-1">
                  <h5>
                    {request.userDetails.firstName} {request.userDetails.lastName}
                  </h5>
                </div>
              </div>

              {PayLayout(request.status)}
            </div>

            {/* ---- */}
            <hr />

            {/* Card info layout */}
            <div className="row mt-4 mb-4">
              <div className="col-lg-12">
                {/* general information */}
                <div>
                  <h6 style={{ fontWeight: "bold" }}>Request Details</h6>
                </div>
                {/* Card details */}
                <div className="row mt-4">
                  <div className="col-lg-3">
                    <p className="mb-0">Date Requested</p>
                    <p className="mt-1" style={{ color: "#898D93" }}>
                    <Moment format="MMMM Do, YYYY">
                      {request.createdAt}
                      </Moment>
                     
                    </p>
                  </div>
                  <div className="col-lg-3">
                      <p className="mb-0">Account Number</p>
                    <p className="mt-1 mb-0" style={{ color: "#898D93" }}>
                      {request.accountNumber ? request.accountNumber : ""}
                    </p>
                  </div>

                  <div className="col-lg-3">
                      <p className="mb-0">Account Name</p>
                    <p className="mt-1 mb-0" style={{ color: "#898D93" }}>
                      {request.accountNumber ? request.accountName : ""}
                    </p>
                  </div>

                  <div className="col-lg-3">
                  <p className="mb-0">Bank Name</p>
                    <p className="mt-1" style={{ color: "#898D93" }}>
                      {request.bankName ? request.bankName : ""}
                    </p>
                  </div>

                  <div className="col-lg-3 mt-3">
                  <p className="mb-0">Bank Code</p>
                    <p className="mt-1 mb-0" style={{ color: "#898D93" }}>
                      {request.bankCode ? request.bankCode : ""}
                    </p>
                  </div>

                  <div className="col-lg-3 mt-3">
                  <p className="mb-0">Amount</p>
                    <p className="mt-1 mb-0" style={{ color: "#898D93" }}>
                        NGN{" "}
                      {request.amount
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        ? request.amount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : ""}
                    </p>
                  </div>

                  <div className="col-lg-3 mt-lg-3 mt-3">
                  <p className="mb-0">Narration</p>
                    <p
                      className="mt-1 mb-0"
                      style={{ color: "#898D93" }}
                    >
                      {request.narration}
                    </p>
                  </div>

                  <div className="col-lg-3 mt-lg-3 mt-3">
                  <p className="mb-0">Status</p>
                    <p
                      className="mt-1 mb-0"
                      style={{
                        color: getPayColor(request.status),
                      }}
                    >
                      {request.status}
                    </p>
                  </div>

                  
                </div>
              </div>
            </div>
          </div>



            </div>
        </div>
        </>
     );
}

const mapStateToProps = (state) => {
    return {
      requests: state.request.requests,
      approveloader: state.request.approveloader,
      declineloader: state.request.declineloader,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      ApproveRequest: (id) => dispatch(ApproveRequest(id)),
      DeclineRequest: (id) => dispatch(DeclineRequest(id)),
    };
  };
 
export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalDetails);