import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

const AdminRoute = ({ children, isAuthenticated}) => {

  return (
    <>
        {
            !isAuthenticated ? 
            <Navigate to ="/"
             />
            :
            children
        }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(AdminRoute);