import {GetApi} from '../helpers'



const getToken = () => {
	const token = localStorage.getItem("token");
	return token
}


   // Get all transactions
   export const getTransactions = () => {
    return async (dispatch, getState) => {
      try {
        dispatch({ type: "LoadTransact"})
        const res = await GetApi("transactions/admin", getToken());
        if (res.status === 200) {
          dispatch({ type: "StopLoadTransact"})
          dispatch({ type: "Transactions", data: res.data.message});
        }
        if(res.status === 400){
          dispatch({ type: "StopLoadTransact"})
          dispatch({ type: "Transact_Error", err: res.data });
        }
      } catch (err) {
        console.log(err)
      }
    };
  };
  