import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// private routes
import AdminRoute from './components/PrivateRoutes/AdminRoutes'

import NotFoundPage from "./pages/404/NotFound";
import LoginPage from "./pages/Admin/Auth/Login";
import ForgotPasswordPage from "./pages/Admin/Auth/Forgot";
import AdminDashboardPage from "./pages/Admin/Dashboard/dashboard";
import AdminTrades from "./pages/Admin/Trades/Trades";
import AdminTradeDetails from "./pages/Admin/Trades/TradeById";
import AdminRates from "./pages/Admin/Rates/rates";
import AdminNewRates from "./pages/Admin/Rates/NewRates";
import UpdateRate from "./pages/Admin/Rates/UpdateRate";
import Admins from "./pages/Admin/Admin/admin";
import ViewAdmin from "./pages/Admin/Admin/viewAdmin";
import AdminProfile from "./pages/Admin/Admin/adminProfile";
import AdminUsers from "./pages/Admin/Users/users";
import UserDetails from "./pages/Admin/Users/usersbyId";
import UserTradeDetails from "./pages/Admin/Users/userTradeById";
import AdminWithdrawals from "./pages/Admin/Withdrawals/Withdrawals";
import AdminTransactions from './pages/Admin/Transactions/Transactions'
import WithdrawalDetails from "./pages/Admin/Withdrawals/WithdrawalById";
import TransactionDetails from "./pages/Admin/Transactions/TransactionById";
import WithdrawSettingsPage from "./pages/Admin/Settings/withdrawSettings";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route  path="/forgotpassword" element={<ForgotPasswordPage />} />

        <Route path="/dashboard" element={
          <AdminRoute>
            <AdminDashboardPage/>
          </AdminRoute>
        } />
        <Route  path="/trades" element={
          <AdminRoute>
            <AdminTrades />
          </AdminRoute>        
              } />
        <Route path="/withdrawals" element={
          <AdminRoute>
            <AdminWithdrawals/>
          </AdminRoute>
          } />
        <Route path="/withdrawal/:id" element={
          <AdminRoute>
                <WithdrawalDetails />
          </AdminRoute>
        } />
        <Route  path="/trade/:id" element={
          <AdminRoute>
            <AdminTradeDetails />
          </AdminRoute>
        } />
        <Route path="/transactions" element={
          <AdminRoute>
             <AdminTransactions/>
          </AdminRoute>     
        } />
        <Route path="/transaction/:id" element={
          <AdminRoute>
           <TransactionDetails />
          </AdminRoute>  
        } />
        <Route  path="/rates" element={
          <AdminRoute>
             <AdminRates />
          </AdminRoute>       
        } />
        <Route  path="/add/rates" element={
          <AdminRoute>
              <AdminNewRates />
          </AdminRoute> 
        } />
        <Route  path="/edit/rate/:id" element={
          <AdminRoute>
            <UpdateRate />
          </AdminRoute>
        } />
        <Route  path="/admin" element={
          <AdminRoute>
              <Admins />
          </AdminRoute>
        } />
        <Route path="/settings" element={
          <AdminRoute>
                <WithdrawSettingsPage/>
          </AdminRoute>
        } />
        <Route  path="/admin/all" element={
          <AdminRoute>
               <ViewAdmin />
          </AdminRoute>
        } />
        <Route  path="/admin/profile" element={
          <AdminRoute>
                <AdminProfile />
          </AdminRoute>

        } />
        <Route  path="/users" element={
          <AdminRoute>
               <AdminUsers />
          </AdminRoute>
        } />
        <Route  path="/user/:id" element={
          <AdminRoute>
              <UserDetails />
          </AdminRoute>
        } />
        <Route  path="/usertrade/:id" element={
          <AdminRoute>
                <UserTradeDetails />
          </AdminRoute>
        } />
        <Route  path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
