import React,{useRef, useState} from 'react';
import Sidebar from '../../../components/Sidebar/Sidebar';
import {connect} from 'react-redux'
import {Link, useNavigate, useParams} from 'react-router-dom'
import ImageZoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { ApproveTrade, DeclineTrade } from '../../../store/actions/admin';
import Modal from 'react-bootstrap/Modal'
import accountCircle from '../../../assets/images/accountCircle.svg'
import closeIcon from '../../../assets/images/closeIcon.svg'
import { Form, Formik } from "formik";
import { DeclineTradeValidator } from "../../../validationSchema/validator";


const UserTradeDetails = (props) => {

    const {trades, ApproveTrade, DeclineTrade, approveloader, declineloader} = props
    const navigate = useNavigate()
    const ref = useRef()

    const {id} = useParams()

    const trade = trades.find(val => val.id === id);

    const [editShow, setEditShow] = useState(false);

    const handleEditClose = () => setEditShow(false);

    const [declineShow, setDeclineShow] = useState(false);

    const handleDeclineClose = () => setDeclineShow(false);
  
    const handleDeclineShow = () => setDeclineShow(true);

    const handleEditShow = () => {
        setEditShow(true);
    }

    // mapping images 
    const imageLayout = trade.imageUrl.filter(el => el !== "").map((item, index) => (
        <div key={index} className="col-lg-3 mb-4">
            <ImageZoom>
            <img
            alt="cards"
            src={item}
            className="card-image"
            />
        </ImageZoom>
         </div>
      ));

      const Approve = (id) =>{
        handleEditShow()
      }

      const Decline = () => {
        handleDeclineShow();
      };


    const handleDecline = async (values) => {
        console.log(values);
    
        const creds = {
          declinedReason: values.reason,
        };
        console.log(creds);
    
        await DeclineTrade(creds, id);
    
        setTimeout(() => {
          navigate("/trades");
        }, 3000);
      };

     
      const AcceptTrade = () =>{
      
        ApproveTrade(id)

      setTimeout(() => {
              navigate('/trades')
       }, 3000);
    }

      const getColor = (status) =>{
        switch(status){
            case 'Pending':
                return '#ff0000'
            case 'Completed':
                return '#00B327'
            case 'Declined':
                return '#ff0000'
            default:
                break;
        }
    }

    

    // approve and decline layout show based on payment status
    const PayLayout = (status) => {
        switch (status) {
          case "Declined":
            return <div></div>;
          case "Pending":
            return <div>
            <button
              type="submit"
              className="btn btn-active mt-lg-0 mt-3"
              onClick={() => {
                Approve(id);
              }}
            >
              Approve Trade
            </button>
            <button
              type="submit"
              disabled={declineloader}
              className="btn btn-suspend ml-lg-3 mt-lg-0 mt-3"
              onClick={() => {
                Decline(id);
              }}
            >
              Decline Trade
            </button>
          </div>;
          case "Completed":
            return <div></div>;
          default:
            return <div></div>;
        }
};


    return ( 
        <>
           {/* modal for details */}
      <Modal show={editShow}
            ref={ref}
            {...props}
            backdrop="static"
         onHide={handleEditClose}>

              <Modal.Header >
             <div onClick={handleEditClose} style={{position: 'absolute', right: '35px', top: '20px', cursor: 'pointer'}}>
                <img src={closeIcon} alt="close" width="40" height="40" />
            </div>
             </Modal.Header>

            {/*container */}
            <div className="d-none d-md-block" style={{position: 'absolute', left: '70px', top: '0px'}}>
                    <img alt="login" src={accountCircle} width="350" height="140" />
             </div>
            

            <div className="text-center contain-head mt-4 mt-lg-5" style={{position: 'relative'}}>
                <h3 className="login-text">Confirm Payment
                </h3>
            </div>

            
            <div className="container modal-contain">
                
                {/* confirm details layout */}
               
                <div className="text-center">
                        <h6 style={{fontWeight: 'bold', lineHeight: '22px'}}>Kindly confirm the approval of this trade.</h6>
                 </div>


                <div className="text-center mt-4">
                <button 
                type="submit"
                disabled={approveloader}
                onClick={AcceptTrade}
                className="btn btn-blueTacit">Approve</button>
                </div>
             
            </div>
        
      </Modal>

          {/* modal for decline trade */}
          <Modal
        show={declineShow}
        ref={ref}
        {...props}
        backdrop="static"
        onHide={handleDeclineClose}
      >
        <Modal.Header>
          <div
            onClick={handleDeclineClose}
            style={{
              position: "absolute",
              right: "35px",
              top: "20px",
              cursor: "pointer",
            }}
          >
            <img src={closeIcon} alt="close" width="40" height="40" />
          </div>
        </Modal.Header>

        {/*container */}
        <div
          className="d-none d-md-block"
          style={{ position: "absolute", left: "70px", top: "0px" }}
        >
          <img alt="login" src={accountCircle} width="350" height="140" />
        </div>

        <div
          className="text-center contain-head mt-4 mt-lg-5"
          style={{ position: "relative" }}
        >
          <h3 className="login-text">Decline Trade</h3>
        </div>

        <div className="container modal-contain">
          {/* confirm details layout */}

          <div className="text-center">
            <h6 style={{ fontWeight: "bold", lineHeight: "22px" }}>
              Kindly confirm the declination of this trade.
            </h6>
          </div>

          {/* reasons text */}
          {/* form */}
          <Formik
            onSubmit={(values, { setSubmitting }) =>
              handleDecline(values, setSubmitting)
            }
            validationSchema={DeclineTradeValidator}
            initialValues={{
              reason: "",
            }}
          >
            {({
              handleChange,
              isSubmitting,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form-group input-container mt-4">
                  <textarea
                    rows="5"
                    className="form-control input-style"
                    id="reason"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.reason}
                    placeholder="Why are you declining?"
                    style={{ resize: "none" }}
                  ></textarea>

                  <small style={{ color: "#dc3545" }}>
                    {touched.reason && errors.reason}
                  </small>
                </div>

                <div className="text-center mt-4">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-blueTacit"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          {/* end of details layout */}
        </div>
      </Modal>
      {/* end of decline trade modal */}


      {/* end of  details modal */}
            <Sidebar />
            <div className="main">
                <div className="contain">

                
                    {/* back button */}
                    <div className="container mt-3 mb-5">

                        <Link to={`/user/${trade.userId}`} style={{textDecoration: 'none', color: 'black', display: 'flex', alignItems: 'center', fontSize: '18px'}}>
                            <span><i className="mdi mdi-arrow-left"></i> Go Back</span>
                        </Link>

                        <hr />

                        
                        {/* Card layout */}
                        <div className="mt-4 mb-4 card-head">
                            <div style={{display: 'flex', alignItems: 'center',}}>
                                <div style={{width: '50px', height: '50px', borderRadius: '50%', background: '#FFDDD2', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <span style={{color:'#CB644E', fontWeight: 'bold'}}>{trade.userDetails.firstName ? trade.userDetails.firstName.charAt(0) : 'O'}{trade.userDetails.lastName ? trade.userDetails.lastName.charAt(0) : 'O'}</span>
                                </div>
                                <div className="ml-3 mt-1">
                                    <h5>{trade.userDetails.firstName ? trade.userDetails.firstName : ''} {trade.userDetails.lastName ? trade.userDetails.lastName : ""}</h5>
                                </div>
                            </div>

                            {PayLayout(trade.tradeStatus)}

                        </div>

                          {/* ---- */}
                          <hr />

                          
                        {/* Card info layout */}
                        <div className="row mt-4 mb-4">
                            <div className="col-lg-7">
                                {/* general information */}
                                <div>
                                    <h6 style={{fontWeight: 'bold'}}>Gift Card Details</h6>
                                </div>

                                {/* Card details */}
                                <div className="row mt-4">
                                    <div className="col-lg-6">
                                        <p className="mb-0" >Category</p>
                                        <p className="mt-1" style={{color: '#898D93'}}>{trade.subCategoryDetails.categoryname ? trade.subCategoryDetails.categoryname : ""}</p>
                                    </div>
                                    <div className="col-lg-6">
                                        <p className="mb-0" >Subcategory</p>
                                        <p className="mt-1 mb-0" style={{color: '#898D93'}}>{trade.subCategoryDetails.subcategoryname ? trade.subCategoryDetails.subcategoryname : ""}</p>
                                    </div>
                                </div>

                                <div className="row mt-lg-3 mt-3">
                                    <div className="col-lg-6">
                                        <p className="mb-0" >Amount</p>
                                        <p className="mt-1 mb-0" style={{color: '#898D93'}}>$ {trade.cardAmount ? trade.cardAmount : 0.00}</p>
                                    </div>
                                    <div className="col-lg-6 mt-lg-0 mt-3">
                                        <p className="mb-0" >Amount to get</p>
                                        <p className="mt-1 mb-0" style={{color: '#898D93'}}>NGN {trade.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                    </div>
                                </div>

                                <div className="row mt-lg-4 mt-3">
                                    <div className="col-lg-6">
                                        <p className="mb-0" >Comment/Notes</p>
                                        <p className="mt-1 mb-0" style={{color: '#898D93'}}>{trade.comment}</p>
                                    </div>
                                    <div className="col-lg-6 mt-lg-0 mt-3">
                                        <p className="mb-0" >Trade Status</p>
                                        <p className="mt-1 mb-0" 
                                        style={{
                                            color: getColor(trade.tradeStatus)
                                            }}
                                        >{trade.tradeStatus}</p>
                                    </div>
                                   
                                </div>

                                
                             

                            </div>

                          
                        </div>

                        <hr />

                        {/*  */}
                        <div className="mt-4 mb-4">
                           <h6 style={{fontWeight: 'bold'}}>Uploaded Cards</h6>
                        </div>

                        {/* cards images */}
                        <div className="row mt-4">
                            {imageLayout}
                        </div>

                    </div>


                </div>
            </div>

        </>
     );
}

const mapStateToProps = (state) =>{
    return{
        trades: state.admin.userTrade,
        approveloader: state.admin.approveloader,
        declineloader: state.admin.declineloader
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
        ApproveTrade: (id) => dispatch(ApproveTrade(id)),
        DeclineTrade: (val,id) => dispatch(DeclineTrade(val,id))
    }
}
 
export default connect(mapStateToProps,mapDispatchToProps)(UserTradeDetails);