

const initState = {
    Transactions: [],
    loader: false
  };
  
  const transactionReducer = (state = initState, action) => {
    switch (action.type) {
    case 'Transactions':
        return{
            ...state,
            Transactions: action.data
        }
    case 'LoadTransact':
        return{
            ...state,
            loader: true
        }
    case "StopLoadTransact":
        return{
            ...state,
            loader: false
        }
      default:
        return state;
    }
  };
  
  export default transactionReducer;
  