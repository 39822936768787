import {PostApi, GetApi} from '../helpers'
import cogoToast from 'cogo-toast';


const getToken = () => {
	const token = localStorage.getItem("token");
	return token
}


   // Get all requests
export const getRequests = () => {
return async (dispatch, getState) => {
    try {
    dispatch({ type: "LoadRequest"})
    const res = await GetApi("withdrawer/request", getToken());
    if (res.status === 200) {
        dispatch({ type: "StopLoadRequest"})
        dispatch({ type: "Requests", data: res.data.message});
    }
    if(res.status === 400){
        dispatch({ type: "StopLoadRequest"})
        dispatch({ type: "Requst_Error", err: res.data });
    }
    } catch (err) {
    console.log(err)
    }
};
};

// approve a withdrawal request
export const ApproveRequest = (id) =>{
    return async (dispatch, getState) => {
      dispatch({ type: "RequestLoader", });
      try {
        const res = await PostApi(`completerequest/flutter/${id}`, "", getToken());
        if (res.status === 200) {
            dispatch({ type: "StopRequestLoader" });
            cogoToast.success('Request processed sucessfully!', { position: 'top-center', })
        }
        if(res.status === 400){
          dispatch({ type: "StopRequestLoader" });
          cogoToast.error('Error while approving request')
        }
      } catch (err) {
        // var message = err.response.data
        console.log(err)
      }
    };
  }

  export const DeclineRequest = (id) =>{
    return async (dispatch, getState) => {
      dispatch({ type: "cancelRequestLoader", });
      try {
        const res = await PostApi(`cancel/withdrawerrequest/${id}`, "", getToken());
        if (res.status === 200) {
            
            dispatch({ type: "StopCancelRequestLoader" });
          cogoToast.success('Request successfully declined!!!', { position: 'top-center', })
        }
        if(res.status === 400){
          dispatch({ type: "StopCancelRequestLoader" });
          cogoToast.error('Error while declining request')
        }
      } catch (err) {
        // var message = err.response.data
        console.log(err)
      }
    };
  }
  