import { combineReducers } from "redux";
import adminReducer from "./admin";
import authReducer from './auth'
import dashboardReducer from './dashboard'
import rateReducer from './rate'
import requestReducer from "./requests";
import transactionReducer from "./transactions";


const appReducer = combineReducers({
    auth: authReducer,
    admin: adminReducer,
    dashboard: dashboardReducer,
    rate: rateReducer,
    transaction: transactionReducer,
    request: requestReducer
  });

const rootReducer = (state, action) => {
  if (action.type === 'logout') {
    state = undefined
  }

    return appReducer(state, action)
  }

    
  export default rootReducer;

  